import { AppError, ErrorCodes } from '../types/errors';

interface ErrorLogParams {
  component?: string;
  action?: string;
  error: Error | AppError;
  user?: any;
  metadata?: Record<string, any>;
  severity?: 'error' | 'warning' | 'info';
}

export function logError({
  component = 'UNKNOWN',
  action = 'UNKNOWN',
  error,
  user,
  metadata,
  severity = 'error'
}: ErrorLogParams) {
  const errorLog = {
    timestamp: new Date().toISOString(),
    component,
    action,
    severity,
    error: {
      code: (error as AppError).code || 'UNKNOWN_ERROR',
      message: error.message,
      details: (error as AppError).details,
      stack: error.stack || new Error().stack,
    },
    user,
    metadata
  };

  // Log to console in development
  if (process.env.NODE_ENV === 'development') {
    console.error('Error Log:', errorLog);
  }

  // Here you could add error reporting services
  // e.g., Sentry, LogRocket, etc.
  
  // You could also send to your backend for logging
  // await sendErrorLog(errorLog);
}

export function createAppError(
  code: string,
  message: string,
  details?: string,
  context?: Record<string, any>
): AppError {
  return {
    code,
    message,
    details,
    context,
    stack: new Error().stack
  };
}

// Helper function to create error with ErrorCodes
export function createTypedError(
  code: keyof typeof ErrorCodes | string,
  message: string,
  details?: string,
  context?: Record<string, any>
): AppError {
  return createAppError(
    code,
    message,
    details,
    context
  );
}

// Re-export AppError type
export type { AppError }; 