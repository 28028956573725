import { useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Menu as MenuIcon, X, Search, Globe, Users, MapPin, Building2, Sparkles, LogIn } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

export default function MobileNav() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  
  // Close menu when route changes
  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  // Prevent body scroll when menu is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const menuItems = [
    { path: '/map', label: 'Global Map', icon: <Globe className="w-5 h-5" /> },
    { path: '/travel-agents', label: 'Directory', icon: <Users className="w-5 h-5" /> },
    { path: '/destinations', label: 'Destinations', icon: <MapPin className="w-5 h-5" /> },
    { path: '/hotel-marketing', label: 'Hotel Marketing', icon: <Building2 className="w-5 h-5" /> },
    { path: '/expo', label: 'Expo', icon: <Sparkles className="w-5 h-5" /> },
    { path: '/agent/login', label: 'Agent Login', icon: <LogIn className="w-5 h-5" /> }
  ];

  return (
    <div className="md:hidden relative z-50">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="fixed top-[18px] right-4 p-2 transition-all duration-300"
        aria-label={isOpen ? 'Close menu' : 'Open menu'}
        aria-expanded={isOpen}
        aria-controls="mobile-menu"
      >
        <div className="relative w-5 h-5 flex items-center justify-center">
          <span 
            className={`absolute h-[1px] bg-gray-200 transform transition-all duration-300
              ${isOpen 
                ? 'w-5 rotate-45 scale-x-100' 
                : 'w-5 scale-x-100'}`}
          />
          <span 
            className={`absolute h-[1px] bg-gray-200 transform transition-all duration-300 delay-75
              ${isOpen 
                ? 'w-5 -rotate-45 scale-x-100' 
                : 'w-3 translate-y-1.5 scale-x-75'}`}
          />
          <span 
            className={`absolute h-5 w-5 rounded-full border border-gray-200/0 transform transition-all duration-300
              ${isOpen ? 'scale-100 border-gray-200/20' : 'scale-0'}`}
          />
        </div>
      </button>

      <AnimatePresence mode="wait">
        {isOpen && (
          <motion.div
            id="mobile-menu"
            role="navigation"
            aria-label="Mobile navigation"
            initial={{ opacity: 0 }}
            animate={{ 
              opacity: 1,
              transition: {
                duration: 0.3,
                ease: "easeOut"
              }
            }}
            exit={{ 
              opacity: 0,
              transition: {
                duration: 0.2,
                ease: "easeIn"
              }
            }}
            className="fixed inset-0 z-40"
          >
            <motion.div 
              initial={{ x: "100%" }}
              animate={{ 
                x: 0,
                transition: {
                  type: "spring",
                  damping: 30,
                  stiffness: 300,
                }
              }}
              exit={{ 
                x: "100%",
                transition: {
                  type: "spring",
                  damping: 30,
                  stiffness: 300,
                }
              }}
              className="absolute inset-0 bg-black/95 backdrop-blur-md shadow-xl"
            >
              <div className="min-h-screen flex flex-col bg-black/95">
                <div className="flex items-center justify-between p-4 border-b border-gray-800">
                  <motion.div
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ 
                      opacity: 1, 
                      x: 0,
                      transition: {
                        delay: 0.2,
                        duration: 0.3
                      }
                    }}
                  >
                    <Link to="/" onClick={() => setIsOpen(false)}>
                      <span className="text-2xl font-display tracking-wider">
                        <span className="text-white">Agen</span>
                        <span className="font-light italic text-gray-400">Trav</span>
                      </span>
                    </Link>
                  </motion.div>
                </div>

                <div className="p-4 border-b border-gray-800">
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Search..."
                      className="w-full pl-12 pr-4 py-3 bg-[#111111] border 
                        border-gray-800 rounded-lg focus:border-white focus:ring-2 
                        focus:ring-white focus:ring-opacity-20 focus:outline-none
                        transition-colors"
                    />
                    <Search className="absolute left-4 top-1/2 -translate-y-1/2 
                      text-gray-400 w-5 h-5" />
                  </div>
                </div>

                <nav className="flex-1 overflow-y-auto py-8 bg-[#000000]">
                  <div className="max-w-lg mx-auto px-4 space-y-2">
                    {menuItems.map((item, index) => (
                      <motion.div
                        key={item.path}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ 
                          opacity: 1, 
                          x: 0,
                          transition: {
                            delay: 0.2 + (index * 0.1),
                            duration: 0.3
                          }
                        }}
                      >
                        <NavLink
                          to={item.path}
                          onClick={() => setIsOpen(false)}
                          className={({ isActive }) => `
                            flex items-center gap-3 w-full p-4 rounded-lg text-lg font-medium 
                            transition-colors
                            ${isActive 
                              ? 'bg-gray-800 text-white' 
                              : 'text-gray-400 hover:bg-gray-800 hover:text-white'}
                          `}
                        >
                          {item.icon}
                          {item.label}
                        </NavLink>
                      </motion.div>
                    ))}
                  </div>
                </nav>

                <div className="p-8 border-t border-gray-800 bg-[#000000]">
                  <div className="max-w-lg mx-auto text-center text-sm text-gray-500">
                    <p>© {new Date().getFullYear()} AgenTrav</p>
                    <p className="mt-1">Connecting elite travel specialists worldwide</p>
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}