export interface AppError {
  code: string;
  message: string;
  details?: string;
  context?: Record<string, any>;
  stack?: string;
}

export const ErrorCodes = {
  AUTH: {
    NO_USER: 'AUTH_NO_USER',
    INVALID_CREDENTIALS: 'AUTH_INVALID_CREDENTIALS',
    SESSION_EXPIRED: 'AUTH_SESSION_EXPIRED'
  },
  DATA: {
    FETCH_FAILED: 'DATA_FETCH_FAILED',
    UPDATE_FAILED: 'DATA_UPDATE_FAILED',
    DELETE_FAILED: 'DATA_DELETE_FAILED'
  },
  HOTEL: {
    PROFILE_NOT_FOUND: 'HOTEL_PROFILE_NOT_FOUND',
    UPDATE_FAILED: 'HOTEL_UPDATE_FAILED',
    INVALID_STATUS: 'HOTEL_INVALID_STATUS'
  },
  SUPABASE: {
    PGRST116: 'PGRST116',
    PGRST200: 'PGRST200'
  }
} as const; 