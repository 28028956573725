import { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import type { User } from '@supabase/supabase-js';
import { ADMIN_EMAIL } from '../lib/supabase';

interface AuthContextType {
  user: User | null;
  loading: boolean;
  login: (email: string, password: string) => Promise<string>;
  logout: () => Promise<void>;
  setUser: (user: User | null) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Check active sessions and sets the user
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user ?? null);
      setLoading(false);
    });

    // Listen for changes on auth state
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null);
    });

    return () => subscription.unsubscribe();
  }, []);

  const login = async (email: string, password: string): Promise<string> => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password
    });

    if (error) throw error;
    if (!data.user) throw new Error('No user returned from login');

    // Check if user is admin
    if (email.toLowerCase() === ADMIN_EMAIL.toLowerCase()) {
      return '/management-portal/dashboard';
    }

    // Check if user is a hotel
    const { data: hotelData, error: hotelError } = await supabase
      .from('hotels')
      .select('id, profile_completed, verified, status')
      .eq('user_id', data.user.id)
      .single();

    if (hotelError && hotelError.code !== 'PGRST116') {
      throw hotelError;
    }

    if (hotelData) {
      // If hotel profile exists but not completed, redirect to completion
      if (!hotelData.profile_completed) {
        return '/hotel/profile/complete';
      }
      // If profile completed but not verified, show dashboard with verification status
      return '/hotel/dashboard';
    }

    // Check if user is an agent
    const { data: agentData } = await supabase
      .from('agents')
      .select('id')
      .eq('user_id', data.user.id)
      .single();

    if (agentData) {
      return '/agent/dashboard';
    }

    throw new Error('User profile not found');
  };

  const logout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
  };

  return (
    <AuthContext.Provider value={{ user, loading, login, logout, setUser }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}