"use client"

import { Toaster } from 'sonner';

export function ToastProvider({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Toaster 
        position="top-right"
        toastOptions={{
          style: {
            background: 'rgb(23, 23, 23)',
            color: '#fff',
            border: '1px solid rgb(38, 38, 38)'
          },
          className: 'toast-theme-dark'
        }}
        closeButton
        richColors
        expand
      />
      {children}
    </>
  );
}

// Add type declaration for module augmentation
declare module 'sonner' {
  interface ToasterProps {
    children?: React.ReactNode;
  }
} 