import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { Loader2 } from 'lucide-react';
import { logError, createTypedError } from '../utils/errorLogger';
import { ErrorCodes } from '../types/errors';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requireHotel?: boolean;
}

export default function ProtectedRoute({ children, requireHotel = false }: ProtectedRouteProps) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        if (!user) {
          logError({
            component: 'ProtectedRoute',
            action: 'checkAuth',
            error: createTypedError(
              ErrorCodes.AUTH.NO_USER,
              'No user found',
              'User authentication check failed'
            ),
            metadata: { requireHotel }
          });
          
          navigate('/login', { 
            state: { 
              from: window.location.pathname,
              message: 'Please login to continue'
            }
          });
          return;
        }

        if (requireHotel) {
          try {
            const { data: hotel, error: hotelError } = await supabase
              .from('hotels')
              .select('id, status, profile_completed')
              .eq('user_id', user.id)
              .single();

            if (hotelError) {
              logError({
                component: 'ProtectedRoute',
                action: 'checkHotelProfile',
                error: createTypedError(
                  ErrorCodes.SUPABASE.PGRST116,
                  hotelError.message,
                  hotelError.details
                ),
                user,
                metadata: { hotel }
              });

              if (hotelError.code === 'PGRST116') {
                navigate('/hotel/profile/complete');
                return;
              }

              throw hotelError;
            }

            if (!hotel) {
              logError({
                component: 'ProtectedRoute',
                action: 'checkHotelProfile',
                error: createTypedError(
                  ErrorCodes.HOTEL.PROFILE_NOT_FOUND,
                  'No hotel profile found'
                ),
                user
              });
              
              navigate('/hotel/profile/complete', {
                state: { message: 'Please complete your hotel profile setup' }
              });
              return;
            }

            if (!hotel.profile_completed) {
              logError({
                component: 'ProtectedRoute',
                action: 'checkProfileCompletion',
                error: createTypedError(
                  ErrorCodes.HOTEL.INVALID_STATUS,
                  'Profile incomplete'
                ),
                user,
                metadata: { hotel }
              });
              
              navigate('/hotel/profile/complete');
              return;
            }

            if (hotel.status === 'pending') {
              logError({
                component: 'ProtectedRoute',
                action: 'checkProfileStatus',
                error: createTypedError(
                  ErrorCodes.HOTEL.INVALID_STATUS,
                  'Profile pending verification'
                ),
                user,
                metadata: { hotel }
              });
              
              navigate('/hotel/verification');
              return;
            }

            if (hotel.status === 'rejected') {
              logError({
                component: 'ProtectedRoute',
                action: 'checkProfileStatus',
                error: createTypedError(
                  ErrorCodes.HOTEL.INVALID_STATUS,
                  'Profile rejected'
                ),
                user,
                metadata: { hotel }
              });
              
              navigate('/hotel/verification');
              return;
            }
          } catch (err) {
            const error = err instanceof Error ? err : new Error('Unknown error');
            logError({
              component: 'ProtectedRoute',
              action: 'hotelProfileCheck',
              error: createTypedError(
                ErrorCodes.HOTEL.UPDATE_FAILED,
                error.message
              ),
              user
            });
            throw error;
          }
        }

        setIsAuthorized(true);
      } catch (err) {
        const error = err instanceof Error ? err : new Error('Unknown error');
        logError({
          component: 'ProtectedRoute',
          action: 'checkAuth',
          error: createTypedError(
            ErrorCodes.AUTH.SESSION_EXPIRED,
            error.message,
            'Session validation failed'
          ),
          user
        });
        
        navigate('/login', {
          state: { 
            message: 'An error occurred. Please try again.' 
          }
        });
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, [user, navigate, requireHotel]);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-background flex items-center justify-center">
        <div className="text-center">
          <Loader2 className="h-12 w-12 animate-spin text-primary mx-auto mb-4" />
          <div className="text-muted-foreground">Verifying access...</div>
        </div>
      </div>
    );
  }

  return isAuthorized ? children : null;
}