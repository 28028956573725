import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';
import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './contexts/AuthContext';
import { ToastProvider } from './components/providers/ToastProvider';
import ProtectedRoute from './components/ProtectedRoute';
import ProtectedAdminRoute from './components/ProtectedAdminRoute';
import Navigation from './components/Navigation';
import LoadingFallback from './components/LoadingFallback';
import { ErrorBoundary } from './components/ErrorBoundary';
import GoogleAnalytics from './components/GoogleAnalytics';
import * as LazyRoutes from './routes/lazyRoutes';

// Helper function to render routes recursively
function renderRoutes(routes: RouteObject[]) {
  return routes.map((route) => (
    <Route
      key={route.path}
      path={route.path}
      element={
        route.element && (
          <ProtectedRoute>
            {route.element}
          </ProtectedRoute>
        )
      }
    >
      {route.children && renderRoutes(route.children)}
    </Route>
  ));
}

// Create a redirect component with error handling
function PasswordResetRedirect() {
  try {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');
    const tokenHash = searchParams.get('token_hash');
    const type = searchParams.get('type');
    
    if (code || tokenHash) {
      const redirectUrl = tokenHash 
        ? `/auth/callback?token_hash=${tokenHash}&type=${type || 'recovery'}&next=/reset-password`
        : `/auth/callback?token_hash=${code}&type=recovery&next=/reset-password`;
      
      window.location.replace(redirectUrl);
      
      return (
        <div className="min-h-screen bg-black flex items-center justify-center">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-accent mx-auto mb-4" />
            <p className="text-muted-foreground">Processing your request...</p>
            <p className="text-sm text-muted-foreground mt-2">
              If you're not redirected, <button 
                onClick={() => window.location.replace(redirectUrl)}
                className="text-accent underline"
              >
                click here
              </button>
            </p>
          </div>
        </div>
      );
    }
    
    if (window.location.pathname === '/') {
      return <LazyRoutes.PublicHome />;
    }

    return <Navigate to="/forgot-password" replace />;
    
  } catch (error) {
    console.error('Password reset redirect error:', error);
    return <Navigate to="/forgot-password" replace />;
  }
}

export default function App() {
  return (
    <HelmetProvider>
      <ErrorBoundary>
        <Router>
          <AuthProvider>
            <ToastProvider>
              <GoogleAnalytics />
              <div className="min-h-screen bg-black flex flex-col">
                <Navigation />
                <main className="flex-grow">
                  <Suspense fallback={<LoadingFallback />}>
                    <Routes>
                      {/* Root route with password reset handling */}
                      <Route 
                        path="/" 
                        element={
                          <ErrorBoundary>
                            <PasswordResetRedirect />
                          </ErrorBoundary>
                        } 
                      />
                      
                      {/* Auth Routes */}
                      <Route path="/auth/callback" element={<LazyRoutes.AuthCallback />} />
                      <Route path="/reset-password" element={<LazyRoutes.ResetPassword />} />
                      <Route path="/forgot-password" element={<LazyRoutes.ForgotPassword />} />
                      <Route path="/login" element={<LazyRoutes.Login />} />
                      <Route path="/register" element={<LazyRoutes.Register />} />
                      <Route path="/agent/login" element={<LazyRoutes.Login userType="agent" />} />
                      <Route path="/hotel/login" element={<LazyRoutes.Login userType="hotel" />} />
                      
                      {/* Public Routes */}
                      <Route path="/" element={<LazyRoutes.PublicHome />} />
                      <Route path="/map" element={<LazyRoutes.MapPage />} />
                      <Route path="/destinations" element={<LazyRoutes.DestinationsPage />} />
                      <Route path="/country/:country" element={<LazyRoutes.CountryPage />} />
                      <Route path="/why-agentrav" element={<LazyRoutes.WhyAgenTrav />} />
                      <Route path="/hotel-marketing" element={<LazyRoutes.HotelMarketing />} />
                      <Route path="/travel-agents" element={<LazyRoutes.AgentsDirectory />} />
                      <Route path="/privacy" element={<LazyRoutes.PrivacyPolicy />} />
                      
                      {/* Admin Routes */}
                      <Route path="/management-portal/auth" element={<LazyRoutes.AdminLogin />} />
                      <Route
                        path="/management-portal/dashboard"
                        element={
                          <ProtectedAdminRoute>
                            <LazyRoutes.AdminDashboard />
                          </ProtectedAdminRoute>
                        }
                      />
                      <Route
                        path="/management-portal/enhance-profiles"
                        element={
                          <ProtectedAdminRoute>
                            <LazyRoutes.EnhanceProfiles />
                          </ProtectedAdminRoute>
                        }
                      />
                      <Route
                        path="/management-portal/clean-data"
                        element={
                          <ProtectedAdminRoute>
                            <LazyRoutes.CleanData />
                          </ProtectedAdminRoute>
                        }
                      />
                      <Route
                        path="/management-portal/cities"
                        element={
                          <ProtectedAdminRoute>
                            <LazyRoutes.CitiesManagement />
                          </ProtectedAdminRoute>
                        }
                      />
                      <Route
                        path="/management-portal/pages"
                        element={
                          <ProtectedAdminRoute>
                            <LazyRoutes.PageManagementMod />
                          </ProtectedAdminRoute>
                        }
                      />
                      
                      {/* Sitemap Routes */}
                      <Route
                        path="/sitemap.xml"
                        element={
                          <ErrorBoundary>
                            <LazyRoutes.SitemapXML />
                          </ErrorBoundary>
                        }
                      />
                      <Route path="/sitemap" element={<LazyRoutes.SitemapPage />} />
                      
                      {/* Expo Routes */}
                      <Route path="/expo" element={<LazyRoutes.ExpoPage />} />
                      <Route path="/expo/:eventId" element={<LazyRoutes.ExpoEventPage />} />
                      
                      {/* Content Pages with prefixes - Must come BEFORE agent profile routes */}
                      <Route 
                        path="/travel-agents/:city/:country/guide/:slug" 
                        element={<LazyRoutes.DynamicPage />} 
                      />
                      <Route 
                        path="/travel-agents/:city/:country/top/:slug" 
                        element={<LazyRoutes.DynamicPage />} 
                      />
                      <Route 
                        path="/travel-agents/:city/:country/best/:slug" 
                        element={<LazyRoutes.DynamicPage />} 
                      />
                      
                      {/* Agent Profile Route - Comes after content routes */}
                      <Route 
                        path="/travel-agents/:city/:country/:agentName" 
                        element={<LazyRoutes.AgentProfilePage />} 
                      />
                      
                      {/* City Pages */}
                      <Route path="/travel-agents/:city/:country" element={<LazyRoutes.CityAgents />} />
                      <Route path="/travel-agents/:country" element={<LazyRoutes.CountryAgents />} />
                      
                      {/* Keep catch-all route last */}
                      <Route path="*" element={<LazyRoutes.DynamicPage />} />
                      
                      {/* Hotel Routes */}
                      <Route path="/hotel">
                        <Route
                          path="dashboard"
                          element={
                            <ProtectedRoute>
                              <LazyRoutes.HotelDashboard />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="create-profile"
                          element={
                            <ProtectedRoute>
                              <LazyRoutes.CreateHotelProfile />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="complete-profile"
                          element={
                            <ProtectedRoute>
                              <LazyRoutes.HotelProfileCompletion />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="profile/edit"
                          element={
                            <ProtectedRoute>
                              <LazyRoutes.HotelProfileEdit />
                            </ProtectedRoute>
                          }
                        />
                        
                        {/* Gallery Routes */}
                        <Route path="gallery">
                          <Route
                            index
                            element={
                              <ProtectedRoute>
                                <LazyRoutes.GalleryLayout />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="photos"
                            element={
                              <ProtectedRoute>
                                <LazyRoutes.Photos />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="upload"
                            element={
                              <ProtectedRoute>
                                <LazyRoutes.UploadPhotos />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="collections"
                            element={
                              <ProtectedRoute>
                                <LazyRoutes.Collections />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="settings"
                            element={
                              <ProtectedRoute>
                                <LazyRoutes.GallerySettings />
                              </ProtectedRoute>
                            }
                          />
                        </Route>
                        
                        {/* Agent Management Routes */}
                        <Route
                          path="agents"
                          element={
                            <ProtectedRoute>
                              <LazyRoutes.MyAgents />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="discover-agents"
                          element={
                            <ProtectedRoute>
                              <LazyRoutes.DiscoverAgents />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="connection-requests"
                          element={
                            <ProtectedRoute>
                              <LazyRoutes.ConnectionRequests />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="messages"
                          element={
                            <ProtectedRoute>
                              <LazyRoutes.AgentMessages />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="network"
                          element={
                            <ProtectedRoute>
                              <LazyRoutes.AgentNetwork />
                            </ProtectedRoute>
                          }
                        />
                      </Route>
                    </Routes>
                  </Suspense>
                </main>
              </div>
            </ToastProvider>
          </AuthProvider>
        </Router>
      </ErrorBoundary>
    </HelmetProvider>
  );
}