import { User } from '@supabase/supabase-js';

export function isAdmin(user: User | null): boolean {
  if (!user?.email) return false;
  
  const isAdminEmail = user.email.toLowerCase() === 'admin@agentrav.com';
  
  // Check both user_metadata and app_metadata for admin status
  const hasAdminMetadata = user.user_metadata?.is_admin === true;
  const hasAdminRole = user.app_metadata?.roles?.includes('admin');
  const isAdminFlag = user.app_metadata?.is_admin === true;
  
  return isAdminEmail && (hasAdminMetadata || hasAdminRole || isAdminFlag);
} 