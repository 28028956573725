import { Link, NavLink } from 'react-router-dom';
import MobileNav from './MobileNav';
import { Globe, Users, MapPin, Building2, Sparkles, LogIn, ChevronDown } from 'lucide-react';

export default function Navigation() {
  const primaryMenuItems = [
    { path: '/map', label: 'Global Map', icon: <Globe className="w-4 h-4" /> },
    { path: '/travel-agents', label: 'Find Agents', icon: <Users className="w-4 h-4" /> },
  ];

  const partnerMenuItems = [
    { path: '/hotel-marketing', label: 'Hotel Marketing', icon: <Building2 className="w-4 h-4" /> },
    { path: '/expo', label: 'Expo', icon: <Sparkles className="w-4 h-4" /> },
  ];

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-black/80 backdrop-blur-sm border-b border-gray-800/50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <Link to="/" className="relative group" aria-label="AgenTrav Home">
            <span className="text-2xl font-display tracking-wider">
              <span className="text-off-white">Agen</span>
              <span className="font-light italic text-gray-400">Trav</span>
            </span>
          </Link>

          <div className="hidden md:flex items-center space-x-8">
            {primaryMenuItems.map((item) => (
              <NavLink
                key={item.path}
                to={item.path}
                className={({ isActive }) => 
                  `text-sm transition-colors flex items-center ${isActive ? 'text-white' : 'text-gray-300 hover:text-white'}`
                }
              >
                {item.label}
              </NavLink>
            ))}

            <div className="relative group flex items-center">
              <button className="text-sm text-gray-300 hover:text-white transition-colors flex items-center gap-1 h-full">
                For Partners
                <ChevronDown className="w-4 h-4" />
              </button>
              <div className="absolute top-full right-0 mt-2 w-48 bg-black/90 backdrop-blur-sm border border-gray-800 rounded-lg shadow-xl opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200">
                {partnerMenuItems.map((item) => (
                  <NavLink
                    key={item.path}
                    to={item.path}
                    className={({ isActive }) => 
                      `flex items-center gap-2 px-4 py-2 text-sm ${
                        isActive ? 'text-white bg-white/10' : 'text-gray-300 hover:text-white hover:bg-white/5'
                      }`
                    }
                  >
                    {item.icon}
                    {item.label}
                  </NavLink>
                ))}
              </div>
            </div>

            <NavLink
              to="/login"
              className="text-sm text-gray-300 hover:text-white transition-colors flex items-center"
            >
              Login
            </NavLink>
          </div>

          <MobileNav />
        </div>
      </div>
    </nav>
  );
}