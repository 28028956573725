import { motion } from 'framer-motion';

interface LoadingFallbackProps {
  message?: string;
}

export default function LoadingFallback({ message = 'Loading...' }: LoadingFallbackProps) {
  return (
    <div className="min-h-screen bg-black flex items-center justify-center">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.2 }}
        className="text-center"
      >
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-accent mx-auto mb-4" />
        <p className="text-muted-foreground">{message}</p>
      </motion.div>
    </div>
  );
}